import 'swiper/css';
import 'swiper/css/pagination';

import Swiper from 'swiper';

new Swiper('.swiper', {
	speed: 4000,
	spaceBetween: 20,
	loop: true,
	autoplay: true
});
